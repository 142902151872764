/* latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/AnonymousPro-Regular.woff2') format('woff2');
}

/* latin */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/AnonymousPro-Bold.woff2') format('woff2');
}
