@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.BusinessCard {
  color: $medium-gray;
  text-align: center;

  h1 {
    font-size: 3em;
    font-weight: normal;
    margin: 0;
  }

  h2 {
    font-size: 1.25em;
    font-weight: normal;
    margin: 0.25em 0;
  }

  .email {
    font-size: 0.95em;
    font-weight: bold;
    margin: 0.5em 0;
  }

  .skills {
    margin-top: 3em;

    & > p {
      margin: 0.33em 0;
    }

    .level1 {
      font-size: 0.95em;
      color: $medium-gray;
    }

    .level2 {
      font-size: 0.87em;
      color: lighten($color: $medium-gray, $amount: 15);
    }

    .level3 {
      font-size: 0.80em;
      color: lighten($color: $medium-gray, $amount: 30);
    }
  }

  @media screen and (max-width: $tiny-phone) {
    font-size: 0.9em;
  }
}
